import React, { useState, FC, useEffect } from 'react';
import { Link } from "react-router-dom";
import Logo1 from "../../images/logo.png"
import Logo2 from "../../images/logo2.png"
import Logo3 from "../../images/logo3.png"
import Menu from "../menu/menu"

interface Props {
    active_page?: string
}

const Header : FC <Props> = (props) => {

    const [scrolled, setScrolled] = useState(false);
    
    useEffect(() => {
        window.onscroll = function() {
            if (window.scrollY > 0) {
            setScrolled(true);
            } else {
            setScrolled(false);
            }
        };
    }, []);

    return (
            <header>
                <section className="header">
                    <div className={scrolled ? "container-fluid top-banner-sticky" : "container-fluid top-banner"}>
                        <div className="row">
                            <div className="col-4">
                                <Link to={{ pathname: '/'}}>
                                    <img src={Logo3} alt="TecDam logo" className="logo"/>
                                </Link>
                            </div>
                            <div className="col-8">
                                <Menu
                                    active_page={props.active_page}
                                />
                            </div>
                        </div>
                    </div>
                </section>    
            </header>
    );
}

export default Header;