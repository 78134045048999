import React, { useState, FC, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from "react-router";
import Wrapper from "../../components/wrapper/wrapper";
import API from '../../helpers/api';
import Helpers from '../../helpers/functions';
import htmr from 'htmr';
import Contactbanner from '../../components/contactbanner/contactbanner';
import {getJobopenings, jobopenings} from '../../redux/pages';
import {getJobopeningpage, jobopeningpage} from '../../redux/pages';
import {getContactpage, contactpage} from '../../redux/pages';
import { useNavigate } from "react-router-dom";

const APIManager = API.instance;
const Validators = Helpers.instance;

interface Props {
}

const Jobdetails : FC <Props> = (props) => {
    const { job_name } = useParams<{ job_name: any }>();
    const [loader, setLoader] = useState(false);
    const dispatch = useDispatch();
    const JOBS = useSelector(jobopenings);
    const PAGE = useSelector(jobopeningpage);
    const CONTACT = useSelector(contactpage);
    const [jobDetail, setJobDetail] = useState<any>(null);
    const navigate = useNavigate();

    useEffect(() => {
        if(loader && JOBS && PAGE && CONTACT !== ''){
            setLoader(false);
            checkIfJobExists();
        }
    }, [JOBS, PAGE, CONTACT]);

    const checkIfJobExists = () => {

        var jobFound = false;
    
        if(JOBS.length > 0){

            for (let i = 0; JOBS.length > i; i++) {
                if(JOBS[i].url == job_name){
                    jobFound = true;
                    setJobDetail(JOBS[i]);
                }
            }

            if(jobFound==false){
                navigate('/jobopenings');
            }

        } else {
            navigate('/jobopenings');
        }
    }

    useEffect(() => {
        setLoader(true);
        dispatch(getJobopenings());
        dispatch(getJobopeningpage());
        dispatch(getContactpage());
    }, []);

    return (
        <Wrapper seo_title={PAGE['seo_title'] && PAGE['seo_title']} seo_description={PAGE['seo_description'] && PAGE['seo_description']} active_page={"jobs"}>
            {loader ? <div className="emptystate"/> :
            jobDetail !== null &&
                <section className="jobdetails mt-5 pt-5">

                    <div className="container mt-5 pt-5">
                        <div className="row justify-content-center">
                            <div className="col-12 col-lg-8">
                                {jobDetail.title &&
                                    <h1 className="page-title mb-3">
                                        {jobDetail.title}
                                    </h1>
                                }
                                {jobDetail.intro &&
                                    <span className="d-block mb-4">
                                        {htmr(jobDetail.intro)}
                                    </span>
                                }
                                {jobDetail.requirements &&
                                    <div className='d-block mb-4'>
                                        {htmr(jobDetail.requirements)}
                                    </div>
                                }
                                {jobDetail.extra_skills &&
                                    <div className='d-block mb-4'>
                                        {htmr(jobDetail.extra_skills)}
                                    </div>
                                }
                                {jobDetail.salary &&
                                    <div className='d-block mb-4'>
                                        {htmr(jobDetail.salary)}
                                    </div>
                                }
                                {jobDetail.outro &&
                                    <span className="d-block">
                                        {htmr(jobDetail.outro)}
                                    </span>
                                }
                            </div>
                        </div>
                    </div>

                    {PAGE.job_details_contact &&
                        <Contactbanner
                            text={PAGE.job_details_contact}
                            //align="justify-content-left"
                            position="col-12 col-lg-8"
                            email={CONTACT.email_address}
                        />
                    }

                </section>
            }
        </Wrapper>
    );
}

export default Jobdetails;