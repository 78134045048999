import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import AboutPage from '../screens/about/about';
import ContactPage from '../screens/contact/contact';
import HomePage from '../screens/home/home';
import JobopeningsPage from '../screens/jobopenings/jobopenings';
import ProjectsPage from '../screens/projects/projects';
import ServicesPage from '../screens/services/services';
import PrivacypolicyPage from '../screens/privacypolicy/privacypolicy';
import JobdetailsPage from '../screens/jobdetails/jobdetails';
import ProjectdetailsPage from '../screens/projectdetails/projectdetails';

import PageBehaviour from './page_behaviour';
import Analytics from './analytics';

const Navigation: FC = (props) => {
    return (
        <Router basename={process.env.PUBLIC_URL}>
            <PageBehaviour />
            <Analytics />
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/about" element={<AboutPage />} />
                <Route path="/contact" element={<ContactPage />} />
                <Route path="/services" element={<ServicesPage />} />
                <Route path="/privacy-policy" element={<PrivacypolicyPage />} />
                <Route path="/jobopenings" element={<JobopeningsPage />} />
                <Route path="/jobopenings/:job_name" element={<JobdetailsPage />} />
                <Route path="/projects" element={<ProjectsPage />} />
                <Route path="/projects/:project_name" element={<ProjectdetailsPage />} />
            </Routes>
        </Router>
    );
};

export default Navigation;
