import React, { useState, FC, useEffect } from 'react';
import I18n from "../../helpers/i18n";
import { Link } from "react-router-dom";
import instagram_logo from '../../images/instagram.png';

interface Props {
}

const Footer : FC <Props> = (props) => {
    return (
        <footer>
            <section className="footer">
                <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-5 footer-text">
                                © TecDam Innovations S.L.
                            </div>
                            <div className="col-5 d-flex justify-content-end">
                                <Link to={{ pathname: '/privacy-policy'}}>
                                    <div className="url">Privacy Statement</div>
                                </Link>  
                                <a target="_blank" href="https://www.instagram.com/tecdam/">
                                    <img src={instagram_logo} alt="Instagram" className="instagram-logo change-opacity-onhover"/>
                                </a> 
                            </div>
                        </div>
                </div>
            </section>
        </footer>
    );
}

export default Footer;