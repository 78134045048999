import React, { useState, FC, useEffect } from 'react';
import axios from 'axios';
import DirectusSDK from '@directus/sdk-js';
import localforage from 'localforage';
import { setupCache } from 'axios-cache-adapter';

const apiUrl = 'https://cms.tecdam.io';
// const apiUser = '';
// const apiPassword = '';

const cache = setupCache({
    maxAge: 5 * 60 * 1000,
    exclude: { query: false }
})

const api = axios.create({
    adapter: cache.adapter
})



api.defaults.headers.post[ 'Content-Type' ] = 'application/x-www-form-urlencoded';

let isRefreshing = false;
let failedQueue: any = [];

export default class API {
    static instance: API = new API();

    directus = new DirectusSDK(apiUrl, { auth: { storage: localforage, mode: 'json' } });
    // storedJwt = cookie.getCookie('token');
    fetchError = null;
   
    get = async (path: string, data: any, meta?: true) => {
        try {
            const { data } = await api.get(`${apiUrl}` + path);
            if (data !== undefined) {
                this.fetchError = null;
                return meta ? data : data.data;
            }
            return null;
        } catch (err: any) {
            this.fetchError = err.message;
            return err;
        }
    }

    getImageBaseURL = (imageID: string) => {
        var fullURL = apiUrl + '/assets/' + imageID;
        return fullURL;
    }
}