import React, { useState, FC, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Wrapper from '../../components/wrapper/wrapper';
import htmr from 'htmr';
import API from '../../helpers/api';
import Contactbanner from '../../components/contactbanner/contactbanner';
import { getTeammembers, teammembers } from '../../redux/pages';
import { getAboutpage, aboutpage } from '../../redux/pages';
import { getContactpage, contactpage } from '../../redux/pages';
import I18n from '../../helpers/i18n';

const APIManager = API.instance;

interface Props {}

const About: FC<Props> = (props) => {
    const [loader, setLoader] = useState(false);
    const dispatch = useDispatch();
    const TEAM = useSelector(teammembers);
    const PAGE = useSelector(aboutpage);
    const CONTACT = useSelector(contactpage);

    useEffect(() => {
        if (loader && TEAM && PAGE && CONTACT !== '') {
            setLoader(false);
        }
    }, [TEAM, PAGE, CONTACT]);

    useEffect(() => {
        setLoader(true);
        dispatch(getTeammembers());
        dispatch(getAboutpage());
        dispatch(getContactpage());
    }, []);

    return (
        <Wrapper
            seo_title={PAGE['seo_title'] && PAGE['seo_title']}
            seo_description={PAGE['seo_description'] && PAGE['seo_description']}
            active_page={'about'}
        >
            {loader ? (
                <div className="emptystate" />
            ) : (
                <section className="about mt-5 pt-5">
                    <div
                        className="container-fluid p-0 m-0 bg-image"
                        style={{
                            backgroundImage:
                                PAGE.banner_photo !== undefined
                                    ? `url(${APIManager.getImageBaseURL(PAGE.banner_photo)})`
                                    : '',
                        }}
                    />
                    {PAGE.about_title && (
                        <div className="container mt-5 mb-3">
                            <div className="row">
                                <div className="col-12 col-lg-8">
                                    <h1 className="page-title">{PAGE.about_title}</h1>
                                </div>
                            </div>
                        </div>
                    )}

                    {PAGE.about_intro && (
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-lg-8">
                                    <span className="content">{htmr(PAGE.about_intro)}</span>
                                </div>
                            </div>
                        </div>
                    )}

                    {PAGE.about_wecreate && (
                        <div className="container mt-4">
                            <div className="row">
                                <div className="col-12 col-lg-8">
                                    <span className="content">{htmr(PAGE.about_wecreate)}</span>
                                </div>
                            </div>
                        </div>
                    )}
                    {PAGE.about_wescrum && (
                        <div className="container mt-4">
                            <div className="row">
                                <div className="col-12 col-lg-8">
                                    <span className="content">{htmr(PAGE.about_wescrum)}</span>
                                </div>
                            </div>
                        </div>
                    )}
                    {PAGE.about_location && (
                        <div className="container mt-4">
                            <div className="row">
                                <div className="col-12 col-lg-8">
                                    <span className="content">{htmr(PAGE.about_location)}</span>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                {PAGE.team_photo !== undefined && (
                                    <div
                                        className="team-photo"
                                        style={{
                                            backgroundImage: `url(${APIManager.getImageBaseURL(PAGE.team_photo)})`,
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                    </div>

                    {PAGE.about_team && (
                        <div className="container mt-5 pt-3">
                            <div className="row">
                                <div className="col-12 col-lg-8">
                                    <span className="content">{htmr(PAGE.about_team)}</span>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="container mb-5 pb-5">
                        <div className="row justify-content-start">
                            {TEAM.length > 0 &&
                                TEAM.map((person: any) => {
                                    return (
                                        <div
                                            className="col-12 col-md-6 col-lg-3 mb-5 change-opacity-onhover"
                                            key={person.id}
                                        >
                                            <a href={'mailto:' + person.email_address} className="member">
                                                <div className="photo-container">
                                                    <div
                                                        className="member-photo"
                                                        style={{
                                                            backgroundImage: `url(${APIManager.getImageBaseURL(
                                                                person.photo
                                                            )})`,
                                                        }}
                                                    />
                                                </div>
                                                <div className="member-name">{person.name}</div>
                                                <div className="member-title">{person.title}</div>
                                            </a>
                                        </div>
                                    );
                                })}
                            <div className="col-12 col-md-6 col-lg-3 mb-5 change-opacity-onhover" key={''}>
                                <a href={'mailto:hola@tecdam.io'} className="member">
                                    <div className="photo-container">
                                        <div className="member-photo new" />
                                    </div>
                                    <div className="member-name">{I18n.t('TEAM_NEW_TITLE')}</div>
                                    <div className="member-title">{I18n.t('TEAM_NEW_TEXT')}</div>
                                </a>
                            </div>
                        </div>
                    </div>
                    {PAGE.about_contact && (
                        <Contactbanner
                            text={PAGE.about_contact}
                            align="justify-content-left"
                            position="col-12 col-lg-10"
                            email={CONTACT.email_address}
                            phone={CONTACT.phonenumber}
                        />
                    )}
                </section>
            )}
        </Wrapper>
    );
};

export default About;
