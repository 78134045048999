
export default class Validators {

    static instance: Validators = new Validators();

    //Make all lower case and replace spaces with an underscore
    makeUrlfriendly(s: string) {
        return s.toLowerCase().replace(/\s/g,'_');
    }

    //Return TRUE if a number is an even number
    isEven(n: number) {
        return n % 2 == 0;
    }
}