import React, { useState, FC, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import I18n from '../../helpers/i18n';
import Wrapper from '../../components/wrapper/wrapper';
import API from '../../helpers/api';
import Helpers from '../../helpers/functions';
import htmr from 'htmr';
import Contactbanner from '../../components/contactbanner/contactbanner';
import { getJobopenings, jobopenings } from '../../redux/pages';
import { getJobopeningpage, jobopeningpage } from '../../redux/pages';
import { getContactpage, contactpage } from '../../redux/pages';
import { Link } from 'react-router-dom';

const APIManager = API.instance;
const Validators = Helpers.instance;

interface Props {}

const Jobopenings: FC<Props> = (props) => {
    const [loader, setLoader] = useState(false);
    const dispatch = useDispatch();
    const JOBS = useSelector(jobopenings);
    const PAGE = useSelector(jobopeningpage);
    const CONTACT = useSelector(contactpage);

    useEffect(() => {
        if (loader && JOBS && PAGE && CONTACT !== '') {
            setLoader(false);
        }
    }, [JOBS, PAGE, CONTACT]);

    useEffect(() => {
        setLoader(true);
        dispatch(getJobopenings());
        dispatch(getJobopeningpage());
        dispatch(getContactpage());
    }, []);

    return (
        <Wrapper
            seo_title={PAGE['seo_title'] && PAGE['seo_title']}
            seo_description={PAGE['seo_description'] && PAGE['seo_description']}
            active_page={'jobs'}
        >
            {loader ? (
                <div className="emptystate" />
            ) : (
                <section className="job-openings mt-5 pt-5">
                    {PAGE.job_opening_title && PAGE.job_opening_intro && PAGE.job_opening_description && (
                        <div className="container mt-5 pt-5">
                            <div className="row justify-content-left">
                                <div className="col-12 col-lg-6">
                                    <h1 className="page-title mb-4">{PAGE.job_opening_title}</h1>
                                    <h3 className="mb-4">{PAGE.job_opening_intro}</h3>
                                    <div>{PAGE.job_opening_description}</div>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="container mt-5">
                        <div className="row justify-content-left">
                            {JOBS.length > 0 &&
                                JOBS.map((item: any) => {
                                    return (
                                        <div className="col-12 col-md-6 col-lg-6 change-opacity-onhover" key={item.id}>
                                            <Link to={{ pathname: '/jobopenings/' + item.url }} aria-label={item.url}>
                                                <div
                                                    className="image"
                                                    style={{
                                                        backgroundImage: `url(${APIManager.getImageBaseURL(
                                                            item.image
                                                        )})`,
                                                    }}
                                                />
                                                <div className="job-title">
                                                    <h3>{item.title}</h3>
                                                </div>
                                            </Link>
                                        </div>
                                    );
                                })}
                        </div>
                    </div>

                    {PAGE.job_opening_contact && (
                        <Contactbanner
                            text={PAGE.job_opening_contact}
                            align="justify-content-left"
                            // position="col-12 col-lg-10"
                            email={CONTACT.email_address}
                        />
                    )}
                </section>
            )}
        </Wrapper>
    );
};

export default Jobopenings;
