import React, { useState, FC, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import htmr from 'htmr';
import I18n from "../../helpers/i18n";
import { Link } from "react-router-dom";
import Wrapper from "../../components/wrapper/wrapper";
import {getPrivacypolicypage, privacypolicypage} from '../../redux/pages';

interface Props {
}

const Privacypolicy : FC <Props> = (props) => {

    const [loader, setLoader] = useState(false);
    const dispatch = useDispatch();
    const PAGE = useSelector(privacypolicypage);

    useEffect(() => {
        if(loader && PAGE !== ''){
            setLoader(false);
        }
    }, [PAGE]);

    useEffect(() => {
        setLoader(true);
        dispatch(getPrivacypolicypage());
    }, []);

    return (
        <Wrapper seo_title={PAGE['seo_title'] && PAGE['seo_title']} seo_description={PAGE['seo_description'] && PAGE['seo_description']}>
            {loader ? 
                <div className="emptystate"/>
            :
                <section className="privacypolicy">
                    {PAGE.privacy_policy_text &&
                        <div className="container">
                            <div className="row justify-content-center">       
                                <div className="col-8">
                                        <span className="content">
                                            {htmr(PAGE.privacy_policy_text)}
                                        </span>
                                </div>
                            </div>
                        </div>  
                    }
                </section>
            }
        </Wrapper>
    );
}

export default Privacypolicy;