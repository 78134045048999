import React, { useState, FC, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import I18n from '../../helpers/i18n';
import Wrapper from '../../components/wrapper/wrapper';
import API from '../../helpers/api';
import Helpers from '../../helpers/functions';
import htmr from 'htmr';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { Link, useLocation } from 'react-router-dom';
import { getProjectspage, projectspage } from '../../redux/pages';
import { getProjects, projects } from '../../redux/pages';
import ProjectBlock from '../../components/project-block';
import ios_store_image from '../../images/appstore-download.png';
import android_store_image from '../../images/googleplay-download.png';

const APIManager = API.instance;
const Validators = Helpers.instance;

interface Props {}

const Projectdetails: FC<Props> = (props) => {
    const { project_name } = useParams<{ project_name: any }>();
    const [loader, setLoader] = useState(false);
    const dispatch = useDispatch();
    const PAGE = useSelector(projectspage);
    const PROJECTS = useSelector(projects);
    const [projectDetail, setProjectDetail] = useState<any>(null);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        setProjectDetail(null);
        setLoader(true);
        dispatch(getProjectspage());
        dispatch(getProjects());
        window.scrollTo({
            top: 0,
            behavior: 'instant',
        } as any);
    }, [location.key]);

    useEffect(() => {
        if (loader && PAGE && PROJECTS !== '') {
            setLoader(false);
            checkIfProjectExists();
        }
    }, [PAGE, PROJECTS]);

    useEffect(() => {
        setProjectDetail(null);
        setLoader(true);
        dispatch(getProjectspage());
        dispatch(getProjects());
    }, []);

    const checkIfProjectExists = () => {
        var projectFound = false;

        if (PROJECTS.length > 0) {
            for (var i = 0; PROJECTS.length > i; i++) {
                if (PROJECTS[i].url == project_name) {
                    projectFound = true;
                    setProjectDetail(PROJECTS[i]);
                }
            }

            if (projectFound == false) {
                navigate('/projects');
            }
        } else {
            navigate('/projects');
        }
    };

    const getRandomProject = () => {
        var copy: any = [].concat(PROJECTS);
        const min = copy.length > 0 ? 1 : 0;
        const max = copy.length + 1;
        const rand = min + Math.random() * (max - min);

        var highlighted = copy.slice(rand - 1, rand);

        if (highlighted[0].url == project_name) {
            var reDo: any = copy.slice(0, 1);
            if (reDo[0].url == project_name) {
                reDo = copy.slice(1, 2);
            }
            return reDo;
        } else {
            return highlighted;
        }
    };

    return (
        <Wrapper
            seo_title={PAGE['seo_title'] && PAGE['seo_title']}
            seo_description={PAGE['seo_description'] && PAGE['seo_description']}
            active_page={'projects'}
        >
            {loader ? (
                <div className="emptystate" />
            ) : (
                projectDetail !== null && (
                    <section className="projectdetails pt-5 mt-5">
                        <div className="container mt-5 pt-5">
                            <div className="row justify-content-left">
                                <div className="col-12 col-lg-6">
                                    {projectDetail.name && <h1 className="page-title mb-4">{projectDetail.name}</h1>}
                                    {projectDetail.intro && (
                                        <span className="bold d-block mb-4">{htmr(projectDetail.intro)}</span>
                                    )}
                                    {projectDetail.about && <span>{htmr(projectDetail.about)}</span>}
                                </div>
                            </div>
                        </div>

                        <div className="container-fluid container-lg p-0">
                            <div className="row">
                                {projectDetail.text_and_images !== null &&
                                    projectDetail.text_and_images !== undefined &&
                                    projectDetail.text_and_images.length > 0 && (
                                        <div className={projectDetail.type == 'app' ? 'col-12 col-lg-10' : 'col-12'}>
                                            {projectDetail.text_and_images.map((item: any, index: any) => {
                                                if (Validators.isEven(index) == false) {
                                                    return (
                                                        <>
                                                            <div
                                                                className="block-holder d-flex justify-content-center justify-content-lg-start align-items-center"
                                                                key={item.project_text_and_images_id.id}
                                                            >
                                                                <div className="slider-bg" />
                                                                {projectDetail.type == 'app' && (
                                                                    <div
                                                                        className="image-app second"
                                                                        style={{
                                                                            backgroundImage: `url(${APIManager.getImageBaseURL(
                                                                                item.project_text_and_images_id.image
                                                                            )})`,
                                                                        }}
                                                                    />
                                                                )}
                                                                {projectDetail.type == 'website' && (
                                                                    <div
                                                                        className="image-website second"
                                                                        style={{
                                                                            backgroundImage: `url(${APIManager.getImageBaseURL(
                                                                                item.project_text_and_images_id.image
                                                                            )})`,
                                                                        }}
                                                                    />
                                                                )}
                                                                <div className="d-none d-lg-block content text-block">
                                                                    {item.project_text_and_images_id.description}
                                                                </div>
                                                            </div>
                                                            <div className="d-block d-lg-none content text-block mb-5">
                                                                {item.project_text_and_images_id.description}
                                                            </div>
                                                        </>
                                                    );
                                                } else {
                                                    return (
                                                        <>
                                                            <div
                                                                className="block-holder d-flex justify-content-center justify-content-lg-end align-items-center"
                                                                key={item.project_text_and_images_id.id}
                                                            >
                                                                <div className="slider-bg" />
                                                                <div className="content d-none d-lg-block text-block second">
                                                                    {item.project_text_and_images_id.description}
                                                                </div>
                                                                {projectDetail.type == 'app' && (
                                                                    <div
                                                                        className="image-app"
                                                                        style={{
                                                                            backgroundImage: `url(${APIManager.getImageBaseURL(
                                                                                item.project_text_and_images_id.image
                                                                            )})`,
                                                                        }}
                                                                    />
                                                                )}
                                                                {projectDetail.type == 'website' && (
                                                                    <div
                                                                        className="image-website"
                                                                        style={{
                                                                            backgroundImage: `url(${APIManager.getImageBaseURL(
                                                                                item.project_text_and_images_id.image
                                                                            )})`,
                                                                        }}
                                                                    />
                                                                )}
                                                            </div>
                                                            <div className="content d-block d-lg-none text-block second mb-5">
                                                                {item.project_text_and_images_id.description}
                                                            </div>
                                                        </>
                                                    );
                                                }
                                            })}
                                        </div>
                                    )}
                            </div>
                        </div>

                        <div className="container">
                            <div className="row justify-content-left">
                                <div className="col-12 col-lg-6">
                                    {projectDetail.outro && (
                                        <span className="content">{htmr(projectDetail.outro)}</span>
                                    )}
                                </div>
                            </div>
                        </div>
                        {projectDetail.website_url !== null && (
                            <div className="container mt-4 mb-5">
                                <div className="row justify-content-left">
                                    <div className="col-12 mb-4">
                                        <span className="content">{I18n.t('PROJECT_WEBSITE_TEXT')}</span>
                                    </div>
                                    <div className="col-12">
                                        <a href={projectDetail.website_url} target="_blank" className="button">
                                            <span className="content">{I18n.t('PROJECT_WEBSITE_URL')}</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        )}
                        {(projectDetail.appstore_ios !== null || projectDetail.appstore_android !== null) && (
                            <div className="container mt-3">
                                <div className="row">
                                    <div className="col-12 mb-4">
                                        <span className="content">{I18n.t('PROJECT_APPSTORE_TEXT')}</span>
                                    </div>

                                    <div className="col-12 d-flex">
                                        {projectDetail.appstore_ios !== null && (
                                            <a
                                                href={projectDetail.appstore_ios}
                                                target="_blank"
                                                aria-label="iOS appstore image"
                                            >
                                                <div
                                                    className="appstore-image change-opacity-onhover"
                                                    style={{ backgroundImage: `url(${ios_store_image})` }}
                                                />
                                            </a>
                                        )}
                                        {projectDetail.appstore_android !== null && (
                                            <a
                                                href={projectDetail.appstore_android}
                                                target="_blank"
                                                aria-label="Android appstore image"
                                            >
                                                <div
                                                    className="appstore-image change-opacity-onhover"
                                                    style={{ backgroundImage: `url(${android_store_image})` }}
                                                />
                                            </a>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </section>
                )
            )}

            {PROJECTS.length > 0 && (
                <div className="container mt-5 mb-5 pb-5 pt-5">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="mb-4">{I18n.t('PROJECT_CHECK_MORE')}</h2>
                            <div className="project-row d-none d-lg-flex">
                                {getRandomProject().map((item: any, index: any) => {
                                    return <ProjectBlock data={item} full={true} reload />;
                                })}
                            </div>
                            <div className="project-row d-flex d-lg-none">
                                {getRandomProject().map((item: any, index: any) => {
                                    return <ProjectBlock data={item} full={false} reload />;
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-12 d-flex justify-content-start">
                            <Link to={{ pathname: '/projects' }}>
                                <div className="button">{I18n.t('BTN_HOME_MORE')}</div>
                            </Link>
                        </div>
                    </div>
                </div>
            )}
        </Wrapper>
    );
};

export default Projectdetails;
