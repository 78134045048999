import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../store';
import API from '../../helpers/api';
const APIManager = API.instance;

interface thisState {
    teammembers: any;
    ourservices: any;
    projects: any;
    otherclients: any;
    jobopenings: any;
    homepage: any;
    aboutpage: any;
    projectspage: any;
    servicespage: any;
    jobopeningpage: any;
    contactpage: any;
    privacypolicypage: any;
}

const initialState: thisState = {
    teammembers: '',
    ourservices: '',
    projects: '',
    otherclients: '',
    jobopenings: '',
    homepage: '',
    aboutpage: '',
    projectspage: '',
    servicespage: '',
    jobopeningpage: '',
    contactpage: '',
    privacypolicypage: '',
};

export const thisSlice = createSlice({
    name: 'pages',
    initialState,
    reducers: {
        setTeammembers: (state, action: PayloadAction<[]>) => {
            state.teammembers = action.payload;
        },

        setOurservices: (state, action: PayloadAction<[]>) => {
            state.ourservices = action.payload;
        },

        setProjects: (state, action: PayloadAction<[]>) => {
            state.projects = action.payload;
        },

        setOtherclients: (state, action: PayloadAction<[]>) => {
            state.otherclients = action.payload;
        },

        setJobopenings: (state, action: PayloadAction<[]>) => {
            state.jobopenings = action.payload;
        },

        setHomepage: (state, action: PayloadAction<[]>) => {
            state.homepage = action.payload;
        },

        setAboutpage: (state, action: PayloadAction<[]>) => {
            state.aboutpage = action.payload;
        },

        setProjectspage: (state, action: PayloadAction<[]>) => {
            state.projectspage = action.payload;
        },

        setServicespage: (state, action: PayloadAction<[]>) => {
            state.servicespage = action.payload;
        },

        setJobopeningpage: (state, action: PayloadAction<[]>) => {
            state.jobopeningpage = action.payload;
        },

        setContactpage: (state, action: PayloadAction<[]>) => {
            state.contactpage = action.payload;
        },

        setPrivacypolicypage: (state, action: PayloadAction<[]>) => {
            state.privacypolicypage = action.payload;
        },
    },
});

export const {
    setTeammembers,
    setOurservices,
    setProjects,
    setOtherclients,
    setJobopenings,
    setHomepage,
    setAboutpage,
    setProjectspage,
    setServicespage,
    setJobopeningpage,
    setContactpage,
    setPrivacypolicypage,
} = thisSlice.actions;

export const getTeammembers = (): AppThunk => (dispatch) => {
    APIManager.get('/items/team_members?filter[status][_eq]=published', '').then((data) => {
        if (data !== null && data !== undefined) {
            dispatch(setTeammembers(data));
        }
    });
};

export const getOurservices = (): AppThunk => (dispatch) => {
    APIManager.get('/items/our_services?filter[status][_eq]=published', '').then((data) => {
        if (data !== null && data !== undefined) {
            dispatch(setOurservices(data));
        }
    });
};

export const getProjects = (): AppThunk => (dispatch) => {
    APIManager.get('/items/projects?fields=*.*.*&filter[status][_eq]=published', '').then((data) => {
        if (data !== null && data !== undefined) {
            dispatch(setProjects(data));
        }
    });
};

export const getOtherclients = (): AppThunk => (dispatch) => {
    APIManager.get('/items/other_clients', '').then((data) => {
        if (data !== null && data !== undefined) {
            dispatch(setOtherclients(data));
        }
    });
};

export const getJobopenings = (): AppThunk => (dispatch) => {
    APIManager.get('/items/job_openings', '').then((data) => {
        if (data !== null && data !== undefined) {
            dispatch(setJobopenings(data));
        }
    });
};

export const getHomepage = (): AppThunk => (dispatch) => {
    APIManager.get('/items/home', '').then((data) => {
        if (data !== null && data !== undefined) {
            dispatch(setHomepage(data));
        }
    });
};

export const getAboutpage = (): AppThunk => (dispatch) => {
    APIManager.get('/items/about', '').then((data) => {
        if (data !== null && data !== undefined) {
            dispatch(setAboutpage(data));
        }
    });
};

export const getProjectspage = (): AppThunk => (dispatch) => {
    APIManager.get('/items/projects_page', '').then((data) => {
        if (data !== null && data !== undefined) {
            dispatch(setProjectspage(data));
        }
    });
};

export const getServicespage = (): AppThunk => (dispatch) => {
    APIManager.get('/items/services', '').then((data) => {
        if (data !== null && data !== undefined) {
            dispatch(setServicespage(data));
        }
    });
};

export const getJobopeningpage = (): AppThunk => (dispatch) => {
    APIManager.get('/items/job_opening', '').then((data) => {
        if (data !== null && data !== undefined) {
            dispatch(setJobopeningpage(data));
        }
    });
};

export const getContactpage = (): AppThunk => (dispatch) => {
    APIManager.get('/items/contact', '').then((data) => {
        if (data !== null && data !== undefined) {
            dispatch(setContactpage(data));
        }
    });
};

export const getPrivacypolicypage = (): AppThunk => (dispatch) => {
    APIManager.get('/items/privacy_policy', '').then((data) => {
        if (data !== null && data !== undefined) {
            dispatch(setPrivacypolicypage(data));
        }
    });
};

export const teammembers = (state: RootState) => state.pages.teammembers;
export const ourservices = (state: RootState) => state.pages.ourservices;
export const projects = (state: RootState) => state.pages.projects;
export const otherclients = (state: RootState) => state.pages.otherclients;
export const jobopenings = (state: RootState) => state.pages.jobopenings;
export const homepage = (state: RootState) => state.pages.homepage;
export const aboutpage = (state: RootState) => state.pages.aboutpage;
export const projectspage = (state: RootState) => state.pages.projectspage;
export const servicespage = (state: RootState) => state.pages.servicespage;
export const jobopeningpage = (state: RootState) => state.pages.jobopeningpage;
export const contactpage = (state: RootState) => state.pages.contactpage;
export const privacypolicypage = (state: RootState) => state.pages.privacypolicypage;

export default thisSlice.reducer;
