import React, { useState, FC, useEffect } from 'react';
import I18n from "../../helpers/i18n";
import { Link } from "react-router-dom";
import Menu_closed from "../../images/menu-closed2.png"
import Menu_open from "../../images/menu-open2.png"

interface Props {
    active_page?: string
}

const Menu : FC <Props> = (props) => {

    const [hamburgerOpen, setHamburgerOpen] = useState(false);

    const toggleHamburger = () =>{

        setHamburgerOpen(!hamburgerOpen);
    }

    const menuArray = [
    {
        name: I18n.t('MENU_HOME'),
        url: '/',
        active: 'home'
    },
    {
        name: I18n.t('MENU_ABOUT'),
        url: '/about',
        active: 'about'
    },
    {
        name: I18n.t('MENU_PROJECTS'),
        url: '/projects',
        active: 'projects'
    },
    {
        name: I18n.t('MENU_SERVICES'),
        url: '/services',
        active: 'services'
    },
    {
        name: I18n.t('MENU_JOBS'),
        url: '/jobopenings',
        active: 'jobs'
    },
    {
        name: I18n.t('MENU_CONTACT'),
        url: '/contact',
        active: 'contact'
    },
    ]
  
    return (
        <div className="menu-wrapper">
            <div className="d-flex justify-content-end">
                <div className="on-desktop">
                    {(menuArray.length > 0) &&
                        menuArray.map((item: any, index: any) =>
                        (
                            <Link key={index} to={{ pathname: item.url}}>
                                <span key={index} className={props.active_page==item.active ? "active" : ""}>
                                    {item.name}
                                </span>
                            </Link>
                        )
                    )}
                </div>
                <div className="d-block d-lg-none">
                    <img src={Menu_open} className="menu-icon" alt="menu" onClick={toggleHamburger}/>
                </div>
            </div>

            <div className={`menu-dark-leftside ${hamburgerOpen && "open"}`} onClick={toggleHamburger}/>

            <div className={`mobile-menu ${hamburgerOpen && "open"}`}>
                <img src={Menu_closed} className="menu-icon-close" alt="menu" onClick={toggleHamburger}/>
                {(menuArray.length > 0) &&
                        menuArray.map((item: any, index: any) =>
                        (
                            <Link key={index} to={{ pathname: item.url}}>
                                <div key={index} className={props.active_page==item.active ? "active" : ""}>
                                    {item.name}
                                </div>
                            </Link>
                        )
                    )}
            </div>
        </div>
    );
}

export default Menu;