import React, { useState, FC, useEffect } from 'react';
import htmr from 'htmr';

interface Props {
    text: string,
    position?: string,
    align?: string,
    phone?: string,
    email?: string
}

const Contactbanner : FC <Props> = (props) => {

    const getPosition = (pos: any) => {
        if(pos!=null) {
            return pos;
        } else
        {
            return "col-12 col-lg-10";
        }
    }

    const getAlignment = (alignment: any) => {
        if(alignment!=null) {
            return alignment;
        } else
        {
            return "justify-content-center";
        }
    }

    return (
        <>
            <div className="container collab-margins">
                <div className={"row "+getAlignment(props.align)}>       
                    <div className={getPosition(props.position)+" contact-title"}>
                        {props.text &&
                            <div className="contact-content">
                                {htmr(props.text)}
                            </div>
                        }
                        {props.email &&
                            <div>
                                <a href={"mailto:" + props.email} aria-label="E-mail address">
                                    {props.email}
                                </a>
                            </div>
                        }
                        {props.phone &&
                            <div>
                                <a href={"tel:" + props.phone} aria-label="Phone number">
                                    {props.phone}
                                </a>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default Contactbanner;