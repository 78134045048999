import React, { useState, FC, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import htmr from 'htmr';
import I18n from "../../helpers/i18n";
import * as emailjs from '@emailjs/browser';
import Wrapper from "../../components/wrapper/wrapper";
import {getContactpage, contactpage} from '../../redux/pages';

interface Props {
}

const Contacts : FC <Props> = (props) => {
    const [loader, setLoader] = useState(false);
    const dispatch = useDispatch();
    const PAGE = useSelector(contactpage);

    const serviceID = 'default_service';
    const templateID = 'template_slfuy6k';
    const userID = 'user_ZDeIdRP633ckNY7mTbRDD';

    const form = useRef() as any;
    const inputName = useRef() as any;
    const inputEmail = useRef() as any;
    const inputMessage = useRef() as any;
    const [anyfieldsempty, setAnyfieldsempty] = useState(false);
    const [messagesent, setMessagesent] = useState(false);
    const [messagefailed, setMessagefailed] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);

    useEffect(() => {
        if(loader && PAGE !== ''){
            setLoader(false);
        }
    }, [PAGE]);

    useEffect(() => {
        setLoader(true);
        dispatch(getContactpage());
    }, []);

    const handleSubmit = (event:any) => {
        event.preventDefault();
        if(inputName.current.value != "" && inputEmail.current.value != "" && inputMessage.current.value != "") {
            setButtonLoader(true);
            emailjs.init(userID);
            emailjs.sendForm(serviceID, templateID, form.current)
                .then(function(response) {
                    //console.log('SUCCESS!', response.status, response.text);
                    setMessagesent(true);
                    inputName.current.value = "";
                    inputEmail.current.value = "";
                    inputMessage.current.value = "";
                    setButtonLoader(false);
                }, function(error) {
                    //console.log('FAILED...', error);
                    setMessagefailed(true);
                    setButtonLoader(false);
                });
        } else {
            setMessagesent(false);
            setAnyfieldsempty(true);
        }
    }

    const handleChange = (event:any) => {
        setAnyfieldsempty(false);
        setMessagesent(false);
    }

    return (
        <Wrapper seo_title={PAGE['seo_title'] && PAGE['seo_title']} seo_description={PAGE['seo_description'] && PAGE['seo_description']} active_page={"contact"}>
            {loader ? <div className="emptystate"/> :

            <section className="contact mt-5 pt-5">
                <div className="container-fluid p-0 m-0">
                    <iframe className="map" title="Our location" width="100%" height="500" scrolling="no" src="https://maps.google.com/maps?&amp;q=TecDam+Innovations+S.L.+Avinguda+de+la+Verge+del+Puig%2C+44%2C+46540+El+Puig+de+Santa+Maria%2C+Valencia&amp;cid=&amp;t=m&amp;center=TecDam+Innovations+S.L.+Avinguda+de+la+Verge+del+Puig%2C+44%2C+46540+El+Puig+de+Santa+Maria%2C+Valencia&amp;output=embed&amp;z=14&amp;iwloc=A&amp;visual_refresh=true" />
                </div>
                {PAGE.contact_intro &&
                    <div className="container mt-5 pt-5">
                        <div className="row justify-content-center">       
                            <div className="col-12 col-lg-10">
                                <h1 className='mb-3'>
                                    {htmr(PAGE.contact_title)}
                                </h1>
                                <h3>
                                    {htmr(PAGE.contact_intro)}
                                </h3>
                            </div>
                        </div>
                    </div>  
                }

                <div className="container mt-5 pt-5">
                    <div className="row justify-content-center">
                        {PAGE.address && PAGE.phonenumber && PAGE.email_address &&
                            <div className="col-12 col-md-4 col-lg-3 contact-info">
                                {htmr(PAGE.address)}
                                <span className="contact-info-link d-block mt-3">
                                    <a href={"tel:" + PAGE.phonenumber}>
                                        {PAGE.phonenumber}
                                    </a>
                                </span>
                                <span className="contact-info-link">
                                    <a href={"mailto:" + PAGE.email_address}>
                                        {PAGE.email_address}
                                    </a>
                                </span>
                            </div>
                        }

                        <div className="col-12 col-md-8 col-lg-7 form-layout">
                            <form autoComplete="off" onSubmit={handleSubmit} style={{ display: 'grid' }} ref={form}>
                                <div className="form-field d-flex">
                                    <input type="text" name="from_name" placeholder={I18n.t('CONTACT_FORM_NAME') as string} ref={inputName} onChange={handleChange}/>
                                    <input type="text" name="reply_to" placeholder={I18n.t('CONTACT_FORM_EMAIL') as string} ref={inputEmail} onChange={handleChange} />
                                </div>
                                <div className="form-field">
                                    <input type="text" name="message" placeholder={I18n.t('CONTACT_FORM_MESSAGE') as string} ref={inputMessage} onChange={handleChange} />
                                </div>
                                <div className="">
                                    {messagesent && 
                                        <span className="message-sent-success">
                                            {I18n.t('CONTACT_FORM_MSG_SENT')}
                                        </span>
                                    }
                                    {messagefailed && 
                                        <span className="message-status-failed">
                                            {I18n.t('CONTACT_FORM_MSG_FAILED')}
                                        </span>
                                    }
                                    {anyfieldsempty && 
                                        <span className="messsage-anyfieldsempty">
                                            {I18n.t('CONTACT_FORM_ANYFIELDSEMPTY')}
                                        </span>
                                    }
                                    <button type="submit" className="sendbutton" disabled={buttonLoader}>
                                        {buttonLoader ? I18n.t('CONTACT_FORM_SENDING') : I18n.t('CONTACT_FORM_SEND')}
                                    </button>
                                </div>
                                <div className="">
                                    {PAGE.contact_disclaimer &&
                                        <div className="disclaimer">
                                            {htmr(PAGE.contact_disclaimer)}
                                        </div>
                                    }
                                </div>
                            </form>
                        </div>
                    </div>
                </div>  

            </section>
            }
        </Wrapper>
    );
}

export default Contacts;