import React, { useState, FC, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import I18n from '../../helpers/i18n';
import Wrapper from '../../components/wrapper/wrapper';
import API from '../../helpers/api';
import Helpers from '../../helpers/functions';
import htmr from 'htmr';
import Contactbanner from '../../components/contactbanner/contactbanner';
import Collabbanner from '../../components/collabbanner/collabbanner';
import Projectbanner from '../../components/projectbanner/projectbanner';
import { Link } from 'react-router-dom';
import { getProjects, projects } from '../../redux/pages';
import { getProjectspage, projectspage } from '../../redux/pages';
import { getOtherclients, otherclients } from '../../redux/pages';
import { getContactpage, contactpage } from '../../redux/pages';
import arrowblack from '../../images/cta-arrow-black.png';
import ProjectBlock from '../../components/project-block';

const APIManager = API.instance;
const Validators = Helpers.instance;

interface Props {}

const Projects: FC<Props> = (props) => {
    const [loader, setLoader] = useState(false);
    const dispatch = useDispatch();
    const PROJECTS = useSelector(projects);
    const CLIENTS = useSelector(otherclients);
    const PAGE = useSelector(projectspage);
    const CONTACT = useSelector(contactpage);

    useEffect(() => {
        if (loader && PROJECTS && CLIENTS && PAGE && CONTACT !== '') {
            setLoader(false);
        }
    }, [PROJECTS, CLIENTS, PAGE, CONTACT]);

    useEffect(() => {
        setLoader(true);
        dispatch(getProjects());
        dispatch(getOtherclients());
        dispatch(getProjectspage());
        dispatch(getContactpage());
    }, []);

    return (
        <Wrapper
            seo_title={PAGE['seo_title'] && PAGE['seo_title']}
            seo_description={PAGE['seo_description'] && PAGE['seo_description']}
            active_page={'projects'}
        >
            {loader ? (
                <div className="emptystate" />
            ) : (
                <section className="projects mt-5 pt-5">
                    {PAGE.project_page_title && PAGE.project_page_intro && (
                        <div className="container mt-5 pt-5">
                            <div className="row justify-content-center">
                                <div className="col-12 col-lg-10">
                                    <span className="content">
                                        <h1 className="page-title">{PAGE.project_page_title}</h1>
                                    </span>
                                    <span className="d-block content mt-3">
                                        <h3>{PAGE.project_page_intro}</h3>
                                    </span>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="container mt-5 mb-5 pb-5 pt-2">
                        <div className="row">
                            <div className="col-12">
                                <div className="project-row">
                                    {PROJECTS.length > 0 &&
                                        PROJECTS.map((item: any, index: any) => {
                                            var second = false;
                                            if (index % 2 === 0) {
                                                second = true;
                                            }
                                            return <ProjectBlock data={item} full={false} second={second} />;
                                        })}
                                </div>
                            </div>
                        </div>
                    </div>

                    {CLIENTS.length > 0 && (
                        <Collabbanner
                            title={I18n.t('PROJECTS_OTHERCLIENTS_TITLE')}
                            text={I18n.t('PROJECTS_OTHERCLIENTS_TEXT')}
                            //align="justify-content-center"
                            //position="col-12 col-lg-10"
                            clients={CLIENTS}
                        />
                    )}

                    {PAGE.project_page_contact && (
                        <Contactbanner
                            text={PAGE.project_page_contact}
                            // align="justify-content-center"
                            // position="col-12 col-lg-10"
                            email={CONTACT.email_address}
                            phone={CONTACT.phonenumber}
                        />
                    )}
                </section>
            )}
        </Wrapper>
    );
};

export default Projects;
