import React, { useState, FC, useEffect } from 'react';
import API from '../../helpers/api';

const APIManager = API.instance;

interface Clientsdata {
    id: number;
    image: string;
    link: string;
    name: string;
    status: string;
}

interface Props {
    title: string;
    text: string;
    position?: string;
    align?: string;
    clients: Clientsdata[];
}

const Collabbanner: FC<Props> = (props) => {
    const getPosition = (pos: any) => {
        if (pos != null) {
            return pos;
        } else {
            return 'col-12 col-lg-10';
        }
    };

    const getAlignment = (alignment: any) => {
        if (alignment != null) {
            return alignment;
        } else {
            return 'justify-content-center';
        }
    };

    return (
        <section className="collabs">
            <div className="container">
                <div className={'row ' + getAlignment(props.align)}>
                    <div className={getPosition(props.position)}>
                        <h2 className="h1 mb-3">{props.title}</h2>
                        <span className="d-block mb-5">{props.text}</span>
                    </div>
                </div>
            </div>
            <div className="container-fluid clients">
                <div className="row">
                    {props.clients.length > 0 &&
                        props.clients.map((item: any) => {
                            return (
                                <div
                                    className="col-12 col-md-6 col-lg-3 d-flex align-items-center justify-content-center"
                                    key={item.id}
                                >
                                    <a href={item.link} aria-label={item.name} className="client-item" target="_blank">
                                        <img
                                            src={APIManager.getImageBaseURL(item.image)}
                                            className="image"
                                            alt={item.name}
                                        />
                                    </a>
                                </div>
                            );
                        })}
                </div>
            </div>
        </section>
    );
};

export default Collabbanner;
