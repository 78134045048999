import React, { useState, FC, useEffect } from 'react';
import I18n from '../../helpers/i18n';
import { Link } from 'react-router-dom';
import API from '../../helpers/api';

const APIManager = API.instance;

interface Props {
    full?: boolean;
    data?: any;
    second?: boolean;
    reload?: boolean;
}

const Projectbanner: FC<Props> = (props: any) => {
    const getStyle = () => {
        var style = 'project-block-wrapper';

        if (props.full) {
            style = style + ' full';
        } else {
            style = style + ' half';
        }
        return style;
    };

    return props.item !== null ? (
        <Link to={{ pathname: '/projects/' + props.data.url }} aria-label={props.url} className={getStyle()}>
            <div
                className={props.data.type == 'website' ? 'project-block' : 'project-block app'}
                style={props.full ? { borderLeft: '20px solid ' + `${props.data.bg_color}` } : {}}
            >
                {!props.full && (
                    <>
                        <span className="title">{props.data.name}</span>
                        <span className="work">{props.data.work_description}</span>
                    </>
                )}
                {props.data.type == 'website' && props.data.image && props.data.image.filename_disk && (
                    <div
                        className={props.second ? 'image-website second' : 'image-website'}
                        style={{
                            backgroundImage: `url(${APIManager.getImageBaseURL(props.data.image.filename_disk)})`,
                        }}
                    />
                )}
                {props.data.type == 'app' && props.data.image && props.data.image.filename_disk && (
                    <div
                        className="image-app"
                        style={{
                            backgroundImage: `url(${
                                props.full &&
                                props.data.text_and_images !== null &&
                                props.data.text_and_images !== undefined &&
                                props.data.text_and_images.length > 1
                                    ? APIManager.getImageBaseURL(
                                          props.data.text_and_images[1].project_text_and_images_id.image
                                      )
                                    : APIManager.getImageBaseURL(props.data.image.filename_disk)
                            })`,
                        }}
                    />
                )}
                {props.full && (
                    <>
                        {props.data.type == 'app' && (
                            <div
                                className="second-image"
                                style={{
                                    backgroundImage: `url(${APIManager.getImageBaseURL(
                                        props.data.image.filename_disk
                                    )})`,
                                }}
                            />
                        )}
                        <div className={props.data.type == 'app' ? 'content app' : 'content'}>
                            <span className="title">{props.data.name}</span>
                            <span className="work">{props.data.work_description}</span>
                            <span className="description">{props.data.description}</span>
                        </div>
                    </>
                )}
            </div>
        </Link>
    ) : null;
};

export default Projectbanner;
