import { configureStore, ThunkAction, Action, getDefaultMiddleware } from '@reduxjs/toolkit';
import pagesReducer from '../pages';

export const store = configureStore({
    reducer: {
        pages: pagesReducer
    },
    middleware: getDefaultMiddleware({
        serializableCheck: false,
    }),
    devTools: true
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
