import React, { useState, FC, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import I18n from '../../helpers/i18n';
import Wrapper from '../../components/wrapper/wrapper';
import API from '../../helpers/api';
import { Link } from 'react-router-dom';
import Helpers from '../../helpers/functions';
import Collabbanner from '../../components/collabbanner/collabbanner';
import ProjectBlock from '../../components/project-block';

import { getHomepage, homepage } from '../../redux/pages';
import { getProjects, projects } from '../../redux/pages';
import { getOtherclients, otherclients } from '../../redux/pages';

const APIManager = API.instance;
const Validators = Helpers.instance;

interface Props {}

const Home: FC<Props> = (props) => {
    const [loader, setLoader] = useState(false);
    const dispatch = useDispatch();
    const PROJECTS = useSelector(projects);
    const PAGE = useSelector(homepage);
    const CLIENTS = useSelector(otherclients);

    useEffect(() => {
        if (loader && PAGE && CLIENTS && PROJECTS !== '') {
            setLoader(false);
        }
    }, [PAGE, CLIENTS, PROJECTS]);

    useEffect(() => {
        setLoader(true);
        dispatch(getHomepage());
        dispatch(getOtherclients());
        dispatch(getProjects());
    }, []);

    const getRandomHighlight = () => {
        const min = 1;
        const max = 4;
        const rand = min + Math.random() * (max - min);

        return PROJECTS.slice(rand - 1, rand);
    };

    return (
        <Wrapper
            seo_title={PAGE['seo_title'] && PAGE['seo_title']}
            seo_description={PAGE['seo_description'] && PAGE['seo_description']}
            active_page="home"
        >
            {loader ? (
                <div className="emptystate" />
            ) : (
                <section className="home mt-5 pt-5">
                    {PAGE.home_page_title && PAGE.home_intro && (
                        <div className="container mt-5 pt-5">
                            <div className="row justify-content-left">
                                <div className="col-12 col-lg-6">
                                    <h1 className="mb-4">{PAGE.home_page_title}</h1>
                                    <span className="content bold">{PAGE.home_intro}</span>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="container mt-5 mb-5 pb-5 pt-2">
                        <div className="row">
                            <div className="col-12">
                                <h2 className="mb-4">{I18n.t('HOME_PRO_TITLE')}</h2>
                                <div className="project-row d-none d-lg-flex">
                                    {PROJECTS.length > 0 &&
                                        getRandomHighlight().map((item: any, index: any) => {
                                            return <ProjectBlock data={item} full={true} />;
                                        })}
                                </div>
                                <div className="project-row d-flex d-lg-none">
                                    {PROJECTS.length > 0 &&
                                        getRandomHighlight().map((item: any, index: any) => {
                                            return <ProjectBlock data={item} full={false} />;
                                        })}
                                </div>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-12 d-flex justify-content-start">
                                <Link to={{ pathname: '/projects' }}>
                                    <div className="button">{I18n.t('BTN_HOME_MORE')}</div>
                                </Link>
                            </div>
                        </div>
                    </div>

                    {CLIENTS.length > 0 && (
                        <Collabbanner
                            title={I18n.t('HOME_COLLAB_TITLE')}
                            text={I18n.t('HOME_COLLAB_TEXT')}
                            align="justify-content-left"
                            position="col-12 col-lg-10"
                            clients={CLIENTS}
                        />
                    )}
                </section>
            )}
        </Wrapper>
    );
};

export default Home;
