import React, { useState, FC, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import I18n from '../../helpers/i18n';
import Wrapper from '../../components/wrapper/wrapper';
import API from '../../helpers/api';
import htmr from 'htmr';
import Helpers from '../../helpers/functions';
import Contactbanner from '../../components/contactbanner/contactbanner';
import { getOurservices, ourservices } from '../../redux/pages';
import { getServicespage, servicespage } from '../../redux/pages';
import { getContactpage, contactpage } from '../../redux/pages';

const APIManager = API.instance;
const Validators = Helpers.instance;

interface Props {}

const Services: FC<Props> = (props) => {
    const [loader, setLoader] = useState(false);
    const dispatch = useDispatch();
    const SERV = useSelector(ourservices);
    const PAGE = useSelector(servicespage);
    const CONTACT = useSelector(contactpage);

    useEffect(() => {
        if (loader && SERV && PAGE && CONTACT !== '') {
            setLoader(false);
        }
    }, [SERV, PAGE, CONTACT]);

    useEffect(() => {
        setLoader(true);
        dispatch(getOurservices());
        dispatch(getServicespage());
        dispatch(getContactpage());
    }, []);

    return (
        <Wrapper
            seo_title={PAGE['seo_title'] && PAGE['seo_title']}
            seo_description={PAGE['seo_description'] && PAGE['seo_description']}
            active_page={'services'}
        >
            {loader ? (
                <div className="emptystate" />
            ) : (
                <section className="services mt-5 pt-5">
                    {PAGE.services_title && PAGE.services_intro && (
                        <div className="container mt-5 pt-5">
                            <div className="row justify-content-center">
                                <div className="col-12 col-lg-10">
                                    <span className="content">
                                        <h1 className="page-title">{PAGE.services_title}</h1>
                                    </span>
                                    <span className="d-block mt-3 content">
                                        <h3>{PAGE.services_intro}</h3>
                                    </span>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="container">
                        {SERV.length > 0 &&
                            SERV.map((item: any) => {
                                if (Validators.isEven(item.id) == false) {
                                    return (
                                        <div className="row justify-content-center margins" key={item.id}>
                                            <div className="col-12 col-lg-6">
                                                <div
                                                    className="image-left"
                                                    style={{
                                                        backgroundImage: `url(${APIManager.getImageBaseURL(
                                                            item.image
                                                        )})`,
                                                    }}
                                                />
                                            </div>
                                            <div className="col-12 col-lg-6 d-flex flex-column justify-content-center greybackground textbox">
                                                <h3 className="title mb-3">{item.area}</h3>
                                                <span className="content">{item.description}</span>
                                            </div>
                                        </div>
                                    );
                                } else {
                                    return (
                                        <div className="row justify-content-center margins" key={item.id}>
                                            <div className="col-12 col-lg-6 order-lg-1 order-2 d-flex flex-column justify-content-center greybackground textbox right">
                                                <h3 className="title mb-3">{item.area}</h3>
                                                <span className="content">{item.description}</span>
                                            </div>
                                            <div className="col-12 col-lg-6 order-lg-2 order-1">
                                                <div
                                                    className="image-right"
                                                    style={{
                                                        backgroundImage: `url(${APIManager.getImageBaseURL(
                                                            item.image
                                                        )})`,
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    );
                                }
                            })}
                    </div>

                    {PAGE.services_contact && (
                        <Contactbanner
                            text={PAGE.services_contact}
                            email={CONTACT.email_address}
                            phone={CONTACT.phonenumber}
                        />
                    )}
                </section>
            )}
        </Wrapper>
    );
};

export default Services;
